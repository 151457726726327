@import './../../_variables'
@import './../../_mixins'

.analysis-grid.buy
    +setDataGridCellColor($buy-medium)
.analysis-grid.sell
    +setDataGridCellColor($sell-medium)
.analysis-grid.highlight
    +setDataGridCellColor($gold-medium)    


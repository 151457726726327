//Color hex/rgb/rgba
$white: #ffffff
$black: #111111
$header-color:#313131
$footer-color:#313131
$menu-item-hover:#989898
$buy-strong: rgba(8, 153, 29, 0.5)
$sell-strong: rgba(242, 54, 69, 0.5)
$buy-medium: rgba(8, 153, 29, 0.35)
$sell-medium: rgba(242, 54, 69, 0.35)
$buy-light: rgba(8, 153, 29, 0.2)
$sell-light: rgba(242, 54, 69, 0.2)
$up-strong: rgba(8, 153, 29, 0.5)
$down-strong: rgba(242, 54, 69, 0.5)
$up-medium: rgba(8, 153, 29, 0.35)
$down-medium: rgba(242, 54, 69, 0.35)
$up-light: rgba(8, 153, 29, 0.2)
$down-light: rgba(242, 54, 69, 0.2)
$bull-strong: rgba(8, 153, 29, 0.5)
$bear-strong: rgba(242, 54, 69, 0.5)
$bull-light: rgba(8, 153, 29, 0.2)
$bear-light: rgba(242, 54, 69, 0.2)
$gold-strong: rgba(218, 165, 32, 0.5)
$gold-medium: rgba(218, 165, 32, 0.35)
$gold-light: rgba(218, 165, 32, 0.2)
$purple-strong: rgba(128, 0, 128, 0.5)

$main-background-color: $white
$main-text-color: $black
@import './_variables'

*
  box-sizing: border-box
  outline: none

html,
body
  min-height: 100%
  overflow-x: hidden
  margin-bottom: 100px
  clear: both

body 
  position: relative
  margin: 0
  font-family: Calibri
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  color: $main-text-color
  background-color: $main-background-color
  font-size: 14px

.app-header-container
  position: relative
  padding: 1%
  height: 100px
  background-color: $header-color

.app-menu-container
  position: absolute
  top: 40%
  bottom: 40%

.app-content-container
  padding: 2%

.app-footer-container 
  height: 100px
  padding: 2%
  position: fixed
  left: 0
  bottom: 0
  width: 100%
  background-color: $header-color
  color: $white
  z-index: 10000

.menu
  font-family: Calibri

.menu-item 
  border-bottom: 1px solid #ddd

.menu-item a 
  display: block
  padding: .7rem 15px
  color: inherit
  text-decoration: none

.menu-item a:hover
  color: $black
  text-decoration: none
  background-color: $menu-item-hover

.menu-item a:focus
  text-decoration: none

.menu-item a:visited
  color: inherit

.active-menu-item
  background-color: #e8e8f9

.hint 
  padding: .5rem 15px

.hide
  visibility: hidden
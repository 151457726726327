@import './../../_variables'
@import './../../_mixins'

.signal-row
    &--escan
        &--buy
            +setDataGridRowColor($buy-strong)
        &--sell
            +setDataGridRowColor($sell-strong)
    &--cross
        &--golden
            +setDataGridRowColor($gold-strong)
        &--death
            +setDataGridRowColor($purple-strong)
    &--macd4cdiv
        &--bull-strong
            +setDataGridRowColor($bull-strong)
        &--bear-strong
            +setDataGridRowColor($bear-strong)
        &--bull-light
            +setDataGridRowColor($bull-light)
        &--bear-light
            +setDataGridRowColor($bear-light)
    &--uscan
        &--buy
            +setDataGridRowColor($buy-strong)
        &--sell
            +setDataGridRowColor($sell-strong)


